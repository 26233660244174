import './App.css'


function App2() {
// eslint-disable-next-line no-unused-expressions
  return (
    <>
    <div>"YAYYYYY : D"</div>
    <div>
      
      <img src="https://media.tenor.com/FyV2DPmL4j4AAAAC/happy-birthday-celebrate.gif" width="250" height="300"/>
      
    </div>
    
    </>
  )
}

export default App2
