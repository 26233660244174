import './App.css'
import { useRef } from 'react';

export default function index (){


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const style = useRef({
    position: 'absolute',
    top: Math.floor(Math.random()*300)+500,
    left: Math.floor(Math.random()*300)+500,
});
    return(
        
    <>
    <div>
      HI DO YOU WANN HANG OUT?</div>
    <div>
      <img src="https://media.tenor.com/zGm5acSjHCIAAAAM/cat-begging.gif" width="250" height="300"/>
      
    </div>

    <a href = "/app">
    <button>YES </button>
    </a>
    <a href = "/">

    <button style={style.current}>NO</button>

    </a>
    </>
    )
}