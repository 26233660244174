import {Routes, Route} from 'react-router-dom'
import Index from './ind.js'
import Apps from './apps.js'

function App() {

  return (
    <Routes>
      <Route path= "/" element = {<Index/>}/>
      <Route path= "/app" element = {<Apps/>}/>
    </Routes>
  )
}

export default App